import styled from "styled-components"
import { respondTo } from "theme/base/Mixins"

const CustomStyled = styled.div`
  .box-attachment-files-vip {
    background: #ECEDF1;
    padding: 1rem;
    
    .title {
      font-size: 1.2rem;
      text-align: center;
    }

    .options {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;

      & > div {
        flex: 1 1 0%;
      }
    }

    .text-alert {
      color: #FD3A4A;
      margin-top: 1.5rem;
      text-align: center;
    }
  }

  ${respondTo.sm`
    .box-attachment-files-vip {
      .options {
        flex-direction: row;
      }
    }
  `}
`

export default CustomStyled