import React from 'react';
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form';
//import { FileText, X } from 'lucide-react';
//import { DivideIcon as LucideIcon } from 'lucide-react';
import FileUploadStyled from 'components/AdvancedFileUpload/AdvancedFileUpload.styled';
import { IconFileText, IconClose } from "theme/common/Icon.styled"

interface FileUploadProps {
  name: string;
  label: string;
  icon?: any;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors;
  watch: any;
  onDelete: (fieldName: any) => void;
  acceptedTypes?: string;
  maxSize?: number;
}

export const AdvancedFileUpload: React.FC<FileUploadProps> = ({
  name,
  label,
  icon: Icon = IconFileText,
  required = false,
  register,
  errors,
  watch,
  onDelete,
  acceptedTypes = "image/jpeg,image/png,application/pdf",
  maxSize = 5 * 1024 * 1024, // 5MB por defecto
}) => {
  const validateFile = (files: FileList | undefined) => {
    if (required && (!files || files.length === 0)) return "El archivo es obligatorio";
    if (files && files[0]) {
      const file = files[0];
      if (file.size > maxSize) return `El archivo no debe superar ${maxSize / (1024 * 1024)}MB`;
      if (!acceptedTypes.includes(file.type)) return "Formato de archivo no permitido";
    }
    return true;
  };

  return (
    <FileUploadStyled>
      <label className="block">
        <span className="subtitle">
          {label} {required && <span className="required">*</span>}
        </span>
        <div className="box-file">
          <Icon className="icon" />
          <input
            type="file"
            className="hidden"
            accept={acceptedTypes}
            {...register(name, { validate: validateFile })}
          />
          <button
            type="button"
            onClick={() => document.querySelector<HTMLInputElement>(`input[name="${name}"]`)?.click()}
          >
            Seleccionar archivo
          </button>
        </div>
      </label>
      {errors[name] && (
        <p className="text-error">{errors[name]?.message as string}</p>
      )}
      {watch(name)?.[0] && (
        <div className="list-file">
          <p>
            {watch(name)[0].name}
          </p>
          <button
            type="button"
            onClick={() => onDelete(name)}
            className="ico-delete"
          >
            <IconClose className="ico" />
          </button>
        </div>
      )}
    </FileUploadStyled>
  );
};