import { useState, useContext, useEffect } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { attachFileShippingGroup, executeShipingGroupAction, updateShippingGroup, updateShippingGroupTrace } from "services/ShippingGroupService"
import Logger from "classes/Logger"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { NOTIFY_DELIVERED } from "helpers/constHelper"
import { ProcessShippingGroupActionType } from "types/OrderType"
import { EShippinggroupAction } from "enums/shippinggroupEmun"
import useUserPermissions from "hooks/UseUserPermissions"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import { FilesFormType } from "types/FilesFormType"
import { useCancelToken, useEnv } from "hooks"
import { IProcessItemCode } from "interfaces/ISendCode"
import { AuthContext } from "context/context/AuthContext"
import { ShippingTypeEnum } from "enums/OrderEnum"

const usePickupOrder = ({
  setFinishedShippingGroupAction,
  shippingGroupId,
  order,
  isElocker,
  shippinGroupDetail,
  refModal,
}: ProcessShippingGroupActionType) => {
  const [loadingPickup, setLoadingPickup] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const [checkValidateCode, setCheckValidateCode] = useState(false)
  const [canIuseValidateCode, setCanIuseValidateCode] = useState(false)
  const [canIuseSendCode, setCanIuseSendCode] = useState(false)
  const [_client] = useEnv().multiclient.get_user_env()
  const [generatedCode, setGeneratedCode] = useState<any>()
  const { notification, errorHander } = useContext(GlobalContext)
  const [hideOnlyCourier, setHideOnlyCourier] = useState(false)
  const [loadingFiles, setLoadingFiles] = useState(false)
  const [successUploadFiles, setSuccessUploadFiles] = useState(false)
  const [canIuseSendQR, setCanIuseSendQR] = useState(false)
  const { newCancelToken } = useCancelToken()

  const {
    state: { user },
  } = useContext(AuthContext)

  const { permission } = useUserPermissions({
    resources: [
      ResourcePermissionsEnum.pickupElocker,
      ResourcePermissionsEnum.cancelOrder,
      ResourcePermissionsEnum.qrEntryCancel,
      ResourcePermissionsEnum.qrExitCancel,
      ResourcePermissionsEnum.orderPickupCancel,
      ResourcePermissionsEnum.qrPickupMail,
    ],
  })

  const attachFile = async (filesShippinGroup: FileList): Promise<boolean> => {
    const formAttachFile = new FormData()

    for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
      const file = filesShippinGroup[indexFile]
      if (file) formAttachFile.append(`$attach${indexFile}`, file)
    }

    formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
    
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const attachFileTypeVDI = async (formData:any): Promise<boolean> => {
    const formAttachFile = new FormData()

    const identityDocumentExtension = formData.identityDocument[0].name.split('.').pop();
    const validationDocumentExtension = formData.validationDocument[0].name.split('.').pop();

    let fileName = `CI_${shippingGroupId}.${identityDocumentExtension}`
    formAttachFile.append("$attach0", formData.identityDocument[0], fileName)
    
    fileName = `VDI_${shippingGroupId}.${validationDocumentExtension}`
    formAttachFile.append("$attach1", formData.validationDocument[0], fileName)

    if(formData.otherDocuments.length) {
      fileName = formData.otherDocuments[0].name
      formAttachFile.append("$attach2", formData.otherDocuments[0], fileName)
      formAttachFile.append("tags", `CI_${shippingGroupId}.${identityDocumentExtension},VDI_${shippingGroupId}.${validationDocumentExtension},${formData.otherDocuments[0].name}`)
    } else {
      formAttachFile.append("tags", `CI_${shippingGroupId}.${identityDocumentExtension},VDI_${shippingGroupId}.${validationDocumentExtension}`)
    }
    
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const handleClickPickupActionWithFiles = async ({ filesShippinGroup }: FilesFormType) => {
    try {
      setLoadingPickup(true)

      let successAttachFiles: boolean = true
      if (filesShippinGroup) {
        successAttachFiles = await attachFile(filesShippinGroup)
      }
      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: NOTIFY_DELIVERED,
            type: "danger",
          },
        })
        return
      }
      await executeAction(EShippinggroupAction.picked_up, "Productos entregado correctamente")
      setLoadingPickup(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingPickup(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const handleClickActionWithFiles = async ({ filesShippinGroup }: FilesFormType) => {
    try {
      setLoadingFiles(true)      

      let successAttachFiles: boolean = true

      if (filesShippinGroup) {
        successAttachFiles = await attachFile(filesShippinGroup)
      }
      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: NOTIFY_DELIVERED,
            type: "danger",
          },
        })
        return
      }

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Archivos subidos correctamente",
          title: NOTIFY_DELIVERED,
          type: "success",
        },
      })

      setSuccessUploadFiles(true)

      setLoadingFiles(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingFiles(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const executeActionTrace = async () => {

    const bodyTrace = {
      shippingGroupId,
      custom: {
        name: "Entregado/Pendiente VDI",
        description: "Entregado/Pendiente VDI",
      }
    }

    const {
      data: { code },
    } = await updateShippingGroupTrace(bodyTrace, newCancelToken())

    if (code !== TransactionCodeEnum.ok) {
      setFinishedShippingGroupAction(true)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Ha ocurrido un error, al actualizar el subestado.",
          title: NOTIFY_DELIVERED,
          type: "danger",
        },
      })
      return
      //setTimeout(() => window.location.reload(), 1000);
    }
    return
  }

  const updateCustomSGVDI = async () => {

    const bodyUpdate = {
      shippingGroupId,
      custom: {
        delivered_vdi: true,
      }
    }

    const {
      data: { code },
    } = await updateShippingGroup(bodyUpdate, newCancelToken())

    if (code !== TransactionCodeEnum.ok) {

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Ha ocurrido un error, al actualizar el subestado.",
          title: NOTIFY_DELIVERED,
          type: "danger",
        },
      })
      return
      //setTimeout(() => window.location.reload(), 1000);
    }
    return
  }

  const handleClickPickupActionWithFilesVDI = async (formData: any) => {
    try {
      setLoadingPickup(true)

      let successAttachFiles: boolean = true
      if (formData) {
        successAttachFiles = await attachFileTypeVDI(formData)
      }
      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: NOTIFY_DELIVERED,
            type: "danger",
          },
        })
        return
      }

      await updateCustomSGVDI()
      await executeActionTrace()
      await executeAction(EShippinggroupAction.picked_up, "Productos entregado correctamente")
      setLoadingPickup(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingPickup(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const handleClickPickupAction = async () => {
    try {
      setLoadingPickup(true)
      await executeAction(EShippinggroupAction.picked_up, "Productos entregado correctamente")
      setLoadingPickup(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingPickup(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const handleClickCancelAction = async () => {
    try {
      setLoadingCancel(true)
      await executeAction(EShippinggroupAction.cancel, "Orden cancelada correctamente")
      setLoadingCancel(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingCancel(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const executeAction = async (action: EShippinggroupAction, messageResul: string) => {
    const {
      data: { code },
    } = await executeShipingGroupAction({
      shippingGroupId,
      action,
    })

    if (code === TransactionCodeEnum.ok) {
      setFinishedShippingGroupAction(true)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: messageResul,
          title: NOTIFY_DELIVERED,
          type: "success",
        },
      })
      return
    }
  }

  const canCancelOrder = () => {
    if (_client === "wom") {
      return permission[ResourcePermissionsEnum.orderPickupCancel]
    } else {
      return permission[ResourcePermissionsEnum.cancelOrder]
    }
  }

  const shouldBeFilePresentOnSubmit = () => {
    if (!shippinGroupDetail?.custom?.infoElocker || !shippinGroupDetail?.custom?.infoElocker?.isElocker) {
      return true
    }
    return false
  }

  const callbackProcessCode = (code: IProcessItemCode) => {
    setGeneratedCode(code)
  }

  const checkVerificationCode = (text: string) => {
    const code = ((order?.custom?.portabilidad?.toUpperCase() === "SI" || order?.custom?.financiamiento?.toUpperCase() === "SI") && order.shippingType === ShippingTypeEnum.SP && shippinGroupDetail?.custom?.infoElocker?.isElocker && shippinGroupDetail?.custom?.infoEntrega?.codigoEntrega) ? shippinGroupDetail?.custom?.infoEntrega?.codigoEntrega : shippinGroupDetail?.custom?.infoElocker?.codigoRetiro || generatedCode?.code

    if (text.length === code?.length) {
      if (text === code) {
        setCheckValidateCode(true)
      } else {
        refModal.current.open()
      }
    }
  }

  const get_user_courier = () => {
    let couriers = user?.groups
    couriers = couriers?.filter((courier: any) => courier.type === "Courier").map((courier: any) => courier.id)
    return couriers
  }

  useEffect(() => {
    const conditionVerification = Boolean(!isElocker || !shippinGroupDetail?.custom?.infoElocker) || ((order?.custom?.portabilidad?.toUpperCase() === "SI" || order?.custom?.financiamiento?.toUpperCase() === "SI") && order.shippingType === ShippingTypeEnum.SP && shippinGroupDetail?.custom?.infoEntrega?.codigoEntrega && shippinGroupDetail?.custom?.infoElocker?.isElocker)
    setCanIuseSendCode(conditionVerification)
    setCanIuseValidateCode(conditionVerification)

    if(order?.custom?.pending_vdi) {
      setCanIuseSendCode(false)
      setCanIuseValidateCode(false)
    }

    const courier = get_user_courier()

    if(courier?.length)
      setHideOnlyCourier(true)

    
    if((order?.custom?.portabilidad?.toUpperCase() === "SI" || order?.custom?.financiamiento?.toUpperCase() === "SI") && order.shippingType === ShippingTypeEnum.SP && shippinGroupDetail?.custom?.infoElocker?.isElocker && shippinGroupDetail?.custom?.infoEntrega?.codigoEntrega) {
      setGeneratedCode({ code: shippinGroupDetail?.custom?.infoEntrega?.codigoEntrega })
    } else if (shippinGroupDetail?.custom?.infoElocker?.codigoRetiro) {
      setGeneratedCode({ code: shippinGroupDetail?.custom?.infoElocker?.codigoRetiro })
    }
    //eslint-disable-next-line
  }, [isElocker, shippinGroupDetail, user, order])

  useEffect(() => {
    const shippingTypeAvalaible:any = [ShippingTypeEnum.SP, ShippingTypeEnum.ED, ShippingTypeEnum.SD]

    if(
      (permission[ResourcePermissionsEnum.qrPickupMail]) &&
      shippinGroupDetail?.custom?.infoElocker?.isElocker &&
      shippingTypeAvalaible.includes(order?.shippingType)
    )
      setCanIuseSendQR(true)

    //eslint-disable-next-line
  }, [order, shippinGroupDetail, user, permission])

  return {
    handleClickPickupAction,
    handleClickCancelAction,
    handleClickPickupActionWithFiles,
    shouldBeFilePresentOnSubmit,
    checkVerificationCode,
    loadingPickup,
    loadingCancel,
    hasPermissionQREntry: permission[ResourcePermissionsEnum.qrEntryCancel],
    hasPermissionQRExitCancel: permission[ResourcePermissionsEnum.qrExitCancel],
    denyPickupAction: isElocker && !permission[ResourcePermissionsEnum.pickupElocker],
    canCancelOrder: canCancelOrder(),
    checkValidateCode,
    canIuseValidateCode,
    canIuseSendCode,
    callbackProcessCode,
    generatedCode,
    hideOnlyCourier,
    loadingFiles,
    handleClickActionWithFiles,
    handleClickPickupActionWithFilesVDI,
    successUploadFiles,
    canIuseSendQR,
  }
}

export default usePickupOrder
