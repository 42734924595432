import React, { Suspense, useContext, useState } from "react"
import BackBotton from "components/Commons/BackBotton/BackBotton"
import { MaintitleStyled, TitlleSuccess } from "theme/common/Paragraph.styled"
import SkeletonThemeContainer from "components/Commons/Skeleton/SkeletonThemeContainer"
import useGetOrderDetail from "hooks/UseGetOrderDetail"
import IPage from "interfaces/IPage"
import { OrderDetailType, OrderDetailParams } from "types/OrderType"
import { RouteComponentProps, useParams } from "react-router-dom"
import HeaderDetail from "components/OrderDetail/HeaderDetail/HeaderDetail"
import HomeBotton from "components/Commons/HomeBotton/HomeBotton"
import AlertStyled from "theme/common/Alert.styled"
import { IconQrCode } from "theme/common/Icon.styled"
import Skeleton from "react-loading-skeleton"
import { AuthContext } from "context/context/AuthContext"
import { WebAppClientsEnum } from "enums/CommonEnum"
import { clients } from "webapp-clients/Clients"
import UseViewPickupCode from "hooks/UseViewPickupCode"
import UseGetTicketOrder from "hooks/UseGetTicketOrder"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"

const showSourceInfo = (shippingGroupDetail: any) => {
  let template = null
  
  if (shippingGroupDetail?.source?.name) {
    template = (
      <AlertStyled className="m-top-button1" variant="info">
        <p>Source {shippingGroupDetail.source.name}</p>
      </AlertStyled>
    )
  } else if (shippingGroupDetail?.target?.source?.id && shippingGroupDetail?.shippingType === "RT") {
    template = (
      <AlertStyled className="m-top-button1" variant="info">
        <p>Target source {shippingGroupDetail?.target?.source?.id}</p>
      </AlertStyled>
    )
  } else {
    template = null
  }

  return template
}

const showVDI = (order: any) => {
  if(order?.custom?.delivered_vdi){
    return <span className="pending-label close">VDI Entregado</span>
  } else {
    if(order?.custom?.pending_vdi && (order.currentStatus.tag !== EShippinggroupStatus.closed)){
      return <span className="pending-label">Pendiente VDI</span>
    } else {
      return null
    }
  }
}

const OrderDetailHOC = (Component: React.ComponentType<OrderDetailType>) => {
  const WrapperOrderDetail = (props: IPage & RouteComponentProps<OrderDetailParams>) => {
    const {
      state: { user },
    } = useContext(AuthContext)
    const client = clients[user?.mainGroup.id as keyof typeof clients]

    const TagConsolidation = user?.mainGroup?.id === WebAppClientsEnum.privilege && clients.privilege.TagConsolidation
    const TicketOrder = client["TicketOrder" as keyof typeof client]

    const { id, shippingGroupId }: any = useParams()
    const [finishedShippingGroupAction, setFinishedShippingGroupAction] = useState(false)

    const { shippingGroupDetail, order } = useGetOrderDetail(id, shippingGroupId)

    const infoElocker = shippingGroupDetail?.custom?.infoElocker

    const { canIuseViewPickupCode } = UseViewPickupCode()

    const { ticket, loading_ticket } = UseGetTicketOrder({ orderId: id })

    return (
      <SkeletonThemeContainer>
        <BackBotton>
          <MaintitleStyled>{`# ${shippingGroupId}`}</MaintitleStyled>
        </BackBotton>
        {order?.id ? (
          <AlertStyled variant="info" className="status-pending">
            <p className="relevant-title">Orden {order.id}</p>

            {showVDI(order)}
          </AlertStyled>
        ) : (
          <Skeleton width={"100%"} height={25} style={{ lineHeight: "unset" }} />
        )}
        <HeaderDetail order={order} sg={shippingGroupDetail} />

        {TagConsolidation && (
          <Suspense fallback={<Skeleton width={"100%"} height={25} />}>
            <TagConsolidation consolidation={order?.custom?.consolidation} />
          </Suspense>
        )}

        {finishedShippingGroupAction && (
          <>
            <TitlleSuccess>El ShippingGroup fue actualizado correctamente.</TitlleSuccess>
            <HomeBotton />
          </>
        )}

        {showSourceInfo(shippingGroupDetail)}
        
        {!order?.custom?.pending_vdi && canIuseViewPickupCode &&
          <>
            {infoElocker?.isElocker && shippingGroupDetail?.custom?.infoEntrega?.codigoEntrega ? (
              <>
                <AlertStyled className="m-top-button1" variant="info">
                  <IconQrCode /> <p>Código de retiro {shippingGroupDetail?.custom?.infoEntrega?.codigoEntrega}</p>
                </AlertStyled>
              </>
            ) : (!shippingGroupDetail?.custom?.infoElocker?.isElocker && shippingGroupDetail?.custom?.infoElocker?.codigoRetiro &&
              <>
                <AlertStyled className="m-top-button1" variant="info">
                  <IconQrCode /> <p>Código de retiro {shippingGroupDetail?.custom?.infoElocker?.codigoRetiro}</p>
                </AlertStyled>
              </>
            )}
          </>
        }

        <Component
          order={order}
          shippinGroupDetail={shippingGroupDetail}
          shippingGroupId={shippingGroupId}
          finishedShippingGroupAction={finishedShippingGroupAction}
          setFinishedShippingGroupAction={setFinishedShippingGroupAction}
        />

        {TicketOrder && (
          <Suspense fallback={null}>
            <TicketOrder ticket={ticket} loading_ticket={loading_ticket} />
          </Suspense>
        )}
      </SkeletonThemeContainer>
    )
  }

  return WrapperOrderDetail
}

export default OrderDetailHOC
