import React, { Suspense, useContext, useEffect, useRef, useState } from "react"
import SummaryProductList from "components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "hoc/OrderDetailHOC"
import { OrderDetailType } from "types/OrderType"
import usePickupOrder from "pages/DetailOrderScreens/Pickup/UsePickupOrder"
import PickupAction from "components/OrderDetail/PickupProductOrder/PickupAction"
import SkeletonShippinGroupDetail from "components/Commons/Skeleton/SkeletonShippinGroupDetail"
import { IconSpinner } from "theme/common/Icon.styled"
import TagPrintContent from "components/OrderDetail/TagPrint/TagPrintContent"
import { clients } from "webapp-clients/Clients"
import { AuthContext } from "context/context/AuthContext"
import useHideActionButtons from "hooks/UseHideActionButtons"
import { useForm } from "react-hook-form"
import { FilesFormType } from "types/FilesFormType"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaFileUpladerFormFull } from "config/ShemaForms"
import AlertStyled from "theme/common/Alert.styled"
import { IconAttachment, IconCheck, IconFileText, IconFileCheck, IconIdentity } from "theme/common/Icon.styled"
import { ErrorFieldForm } from "theme/common/Paragraph.styled"
import { ButtonStyled } from "theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "theme/common/ContentDetailOrderActions.styled"
import Input from "components/Commons/Input"
import Modal from "components/Commons/Modal/Modal"
import WindowModal from "components/Commons/WindowModal/WindowModal"
import SendCode from "components/OrderDetail/SendCode/SendCode"
import SendQR from "components/OrderDetail/SendQR/SendQR"
import UseViewUploadFiles from "hooks/UseViewUploadFiles"
import { ResourceRoleEnum } from "enums/ResourceRoleEnum"
import { AdvancedFileUpload } from "components/AdvancedFileUpload/AdvancedFileUpload"
import CustomStyled from "theme/base/Custom.styled"

const InfoElocker = React.lazy(() => import("components/OrderDetail/ElockerQR/InfoElocker"))

const PickupScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hidePickupActions, updateVisibilityButtonActions } = useHideActionButtons()
  const [showWithdrawalCode, setShowWithdrawalCode] = useState(false)
  const [rolSellerAndSupCourier, setRolSellerAndSupCourier] = useState(false)
  const [rolSuperRoot, setRolSuperRoot] = useState(false)

  useEffect(() => {
    updateVisibilityButtonActions(shippinGroupDetail?.source?.id, shippinGroupDetail?.target?.source?.id)
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const FileUpload = client["FileUpload" as keyof typeof client]
  const ContentFileMedia = client["ContentFileMedia" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const infoEntrega = shippinGroupDetail?.custom?.infoEntrega
  const documents = shippinGroupDetail?.custom?.documents

  const files = shippinGroupDetail?.status
    .filter(({ media }) => media)
    .flatMap(({ media }) => media?.ref.map(({ id }) => id))

  const filesByStatus = shippinGroupDetail?.status
    .filter(status => status.tag === "IN_TRANSIT" && status.media?.ref?.length)
    .flatMap(({ media }) => media?.ref.map(({ id }) => id))
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerFormFull([])) : undefined,
  })
  

  const filesShippinGroup = watch("filesShippinGroup")
  const [disabledButton, setDisabledButton] = useState(true)
  const [disabledButtonAttachmentVDI, setDisabledButtonAttachmentVDI] = useState(true)
  const [disabledButtonFiles, setDisabledButtonFiles] = useState(false)

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  const {
    loadingCancel,
    loadingPickup,
    handleClickCancelAction,
    handleClickPickupAction,
    hasPermissionQRExitCancel,
    canCancelOrder,
    denyPickupAction,
    shouldBeFilePresentOnSubmit,
    handleClickPickupActionWithFiles,
    checkValidateCode,
    checkVerificationCode,
    canIuseValidateCode,
    canIuseSendCode,
    callbackProcessCode,
    generatedCode,
    hideOnlyCourier,
    loadingFiles,
    handleClickActionWithFiles,
    handleClickPickupActionWithFilesVDI,
    successUploadFiles,
    canIuseSendQR,
  } = usePickupOrder({
    shippingGroupId,
    order,
    setFinishedShippingGroupAction,
    isElocker: elockerInfo?.isElocker,
    shippinGroupDetail,
    refModal,
  })

  const refFormFilesAttachmentVDI = useRef<any>()
  interface FormInputs {
    identityDocument: FileList;
    validationDocument: FileList;
    otherDocuments?: FileList;
  }

  const {
    register: registerAttachmentVDI,
    handleSubmit: handleSubmitAttachmentVDI,
    formState: { errors: errorsAttachmentVDI, isValid: isValidAttachmentVDI },
    watch: watchAttachmentVDI,
    setValue: setValueAttachmentVDI,
    trigger: triggerAttachmentVDI,
  } = useForm<FilesFormType>({
    mode: 'onChange'
  });

  useEffect(() => {
    if(order?.custom?.pending_vdi) {
      //const existSubState = shippinGroupDetail?.trace?.some((item: any) => item.name === "Entregado/Pendiente VDI")
      isValidAttachmentVDI ? setDisabledButtonAttachmentVDI(false) : setDisabledButtonAttachmentVDI(true)
    } else {
      if (canIuseValidateCode) {
        filesShippinGroup?.length && canIuseValidateCode && checkValidateCode
          ? setDisabledButton(false)
          : setDisabledButton(true)
        if((order?.custom?.portabilidad?.toUpperCase() === "SI" || order?.custom?.financiamiento?.toUpperCase() === "SI") && shippinGroupDetail?.custom?.infoElocker?.isElocker) {
          if(files && files?.length < 1)
            setDisabledButtonFiles(true)

          if(successUploadFiles && checkValidateCode)
            setDisabledButtonFiles(false)
        }
        setShowWithdrawalCode(checkValidateCode)
      } else {
        filesShippinGroup?.length ? setDisabledButton(false) : setDisabledButton(true)
        if((order?.custom?.portabilidad?.toUpperCase() === "SI" || order?.custom?.financiamiento?.toUpperCase() === "SI") && shippinGroupDetail?.custom?.infoElocker?.isElocker) {
          if(files && files?.length < 1) {
            setDisabledButtonFiles(true)
          }
        }
        
        if(successUploadFiles)
          setDisabledButtonFiles(false)
      }
    }
  },
  //eslint-disable-next-line
  [filesShippinGroup, canIuseValidateCode, checkValidateCode, order, shippinGroupDetail, successUploadFiles, isValidAttachmentVDI])

  const refFormFiles = useRef<any>()

  const { canIuseViewUploadFiles, canIuseViewUploadFilesCourier } = UseViewUploadFiles()

  useEffect(() => {
    setRolSellerAndSupCourier(user?.role === ResourceRoleEnum["webapp-seller-courier"] || user?.role === ResourceRoleEnum["webapp-sup-courier"])
    setRolSuperRoot(user?.role === ResourceRoleEnum["webapp-super-root"])
    // eslint-disable-next-line
  }, [user])

  const ShowInfoElockerSuppSource = () => {
    if(user?.role === ResourceRoleEnum["webapp-sup-seller-source"]) {
      if(infoEntrega?.codigoEntrega?.length) {
        return (
          <InfoElocker
            codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo?.codigoCancelacion : null}
            codigoEntrega={null}
            codigoRetiro={showWithdrawalCode ? elockerInfo?.codigoRetiro : null}
            puerta={elockerInfo?.puerta || null}
          />
        );
      } else {
        return (
          <InfoElocker
            codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo?.codigoCancelacion : null}
            codigoEntrega={null}
            codigoRetiro={elockerInfo?.codigoRetiro}
            puerta={elockerInfo?.puerta || null}
          />
        );
      }
    } else {
      if(user?.role === ResourceRoleEnum["webapp-seller-source"]) {
        return (
          <InfoElocker
            codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo?.codigoCancelacion : null}
            codigoEntrega={null}
            codigoRetiro={null}
            puerta={elockerInfo?.puerta || null}
          />
        );
      } else {
        if(user?.role !== ResourceRoleEnum["webapp-super-root"]) {
          return (
            <InfoElocker
              codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo?.codigoCancelacion : null}
              codigoEntrega={null}
              codigoRetiro={showWithdrawalCode ? elockerInfo?.codigoRetiro : null}
              puerta={elockerInfo?.puerta || null}
            />
          );
        } else {
          return null
        }
      }
    }
  }

  const handleDeleteFile = async (fieldName: keyof FormInputs) => {
    setValueAttachmentVDI(fieldName, undefined as any, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    await triggerAttachmentVDI(fieldName);
  };

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos por recoger" : undefined}
          />

          {elockerInfo && elockerInfo.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              
              <ShowInfoElockerSuppSource />
              
              {rolSuperRoot && (
                <InfoElocker
                  codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo.codigoCancelacion : null}
                  codigoEntrega={null}
                  codigoRetiro={elockerInfo.codigoRetiro}
                  puerta={elockerInfo.puerta}
                />
              )}
              
            </Suspense>
          )}
          
          {canIuseSendQR && (          
            <SendQR
              orderId={order?.id}
              customer={order?.customer}
              shippingGroupId={shippingGroupId}
              onProcessCode={callbackProcessCode}
              shippingType={order?.shippingType}
            />
          )}

          {canIuseValidateCode && !checkValidateCode && !hideOnlyCourier && (
            <>
              <AlertStyled variant="info">
                <p>Código de verificación</p>
              </AlertStyled>
              <div style={{ padding: 15 }}>
                <Input
                  type="text"
                  name="verificationCode"
                  placeHolder="Código de verificación"
                  onChange={(e) => checkVerificationCode(e.target.value)}
                  disabled={Boolean(!generatedCode?.code)}
                />
              </div>
              <Modal ref={refModal}>
                <WindowModal
                  title="Código de verificación"
                  description="El código de retiro no coincide con el enviado al cliente."
                  handleConfirm={() => {
                    refModal.current?.close()
                  }}
                  showCancel={false}
                />
              </Modal>
            </>
          )}

          {canIuseSendCode && !hideOnlyCourier && (
            <SendCode
              orderId={order?.id}
              customer={order?.customer}
              shippingGroupId={shippingGroupId}
              onProcessCode={callbackProcessCode}
              shippingType={order?.shippingType}
              portabilidad={order?.custom?.portabilidad}
            />
          )}

          {shouldBeFilePresentOnSubmit() && !hideOnlyCourier && !finishedShippingGroupAction && !shippinGroupDetail?.custom?.pending_vdi && (
            <>
              <form ref={refFormFiles} onSubmit={handleSubmit(handleClickPickupActionWithFiles)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section>
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} label="Archivos adjuntos" />
                    </section>
                  </Suspense>
                )}

                {disabledButton && (
                  <AlertStyled variant="danger" className="transparent">
                    <p>Para continuar, necesita adjuntar al menos un archivo</p>
                  </AlertStyled>
                )}

                {errors.filesShippinGroup && <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>}

                <ContentDetailOrderActionsStyled>
                  <ButtonStyled
                    variant="primary"
                    loadingButton={loadingPickup}
                    disabled={loadingPickup}
                    disabledButton={disabledButton}
                    type="submit"
                  >
                    <IconCheck />
                    <span>Confirmar entrega</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </form>
            </>
          )}
          
          {(order?.custom?.portabilidad?.toUpperCase() === "SI" || order?.custom?.financiamiento?.toUpperCase() === "SI") && shippinGroupDetail?.custom?.infoElocker?.isElocker && !successUploadFiles && (
            <>
              <form ref={refFormFiles} onSubmit={handleSubmit(handleClickActionWithFiles)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section>
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} label="Archivos adjuntos" />
                    </section>
                  </Suspense>
                )}

                {disabledButton && (
                  <AlertStyled variant="danger" className="transparent">
                    <p>Para continuar, necesita adjuntar al menos un archivo</p>
                  </AlertStyled>
                )}

                {errors.filesShippinGroup && <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>}

                <ContentDetailOrderActionsStyled>
                  <ButtonStyled
                    variant="primary"
                    loadingButton={loadingFiles}
                    disabled={loadingFiles}
                    disabledButton={disabledButton}
                    type="submit"
                  >
                    <IconCheck />
                    <span>Subir Archivo</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </form>
            </>
          )}
          
          {!finishedShippingGroupAction && shippinGroupDetail?.custom?.pending_vdi && (
            <form ref={refFormFilesAttachmentVDI} onSubmit={handleSubmitAttachmentVDI(handleClickPickupActionWithFilesVDI)}>
              <CustomStyled>
                <AlertStyled variant="info" className="m-top-button1">
                  <IconAttachment />
                  <p>Adjuntar archivos</p>
                </AlertStyled>

                <div className="box-attachment-files-vip">
                  <h1 className="title">Seleccionar archivos</h1>
                  
                  <div className="options">
                    <AdvancedFileUpload
                      name="identityDocument"
                      label="Cédula de Identidad"
                      icon={IconIdentity}
                      required
                      register={registerAttachmentVDI}
                      errors={errorsAttachmentVDI}
                      watch={watchAttachmentVDI}
                      onDelete={handleDeleteFile}
                    />
                    <AdvancedFileUpload
                      name="validationDocument"
                      label="Validación de Identidad"
                      icon={IconFileCheck}
                      required
                      register={registerAttachmentVDI}
                      errors={errorsAttachmentVDI}
                      watch={watchAttachmentVDI}
                      onDelete={handleDeleteFile}
                    />
                    <AdvancedFileUpload
                      name="otherDocuments"
                      label="Otro"
                      icon={IconFileText}
                      register={registerAttachmentVDI}
                      errors={errorsAttachmentVDI}
                      watch={watchAttachmentVDI}
                      onDelete={handleDeleteFile}
                    />
                  </div>

                  <div className="text-alert">Los documentos con * son obligatorios</div>
                </div>

                <ContentDetailOrderActionsStyled>
                  <ButtonStyled
                    variant="primary"
                    loadingButton={loadingPickup}
                    disabled={loadingPickup}
                    disabledButton={disabledButtonAttachmentVDI}
                    type="submit"
                  >
                    <IconCheck />
                    <span>Confirmar entrega</span>
                  </ButtonStyled>
                </ContentDetailOrderActionsStyled>
              </CustomStyled>
            </form>
          )}

          {!finishedShippingGroupAction && !hidePickupActions && !shippinGroupDetail?.custom?.pending_vdi && (
            <PickupAction
              //disabledPickup={checkValidateCode}
              onClickPickup={!denyPickupAction ? handleClickPickupAction : undefined}
              onClickCancel={canCancelOrder ? handleClickCancelAction : undefined}
              loadingCancel={loadingCancel}
              loadingPickup={loadingPickup}
              shouldBeFilePresentOnSubmit={shouldBeFilePresentOnSubmit}
              disabledButton={disabledButtonFiles}
            />
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
              shippinGroupDetail={shippinGroupDetail}
            />
          )}
          
          {canIuseViewUploadFiles  && !canIuseViewUploadFilesCourier && ContentFileMedia && files && files.length > 0 && (
            <Suspense fallback={null}>
              <ContentFileMedia listIdFiles={files} shippingGroupId={shippingGroupId} documents={documents} />
            </Suspense>
          )}
          
          {canIuseViewUploadFilesCourier && ContentFileMedia && filesByStatus !== undefined && filesByStatus.length > 0 && (
            <Suspense fallback={null}>
              <ContentFileMedia listIdFiles={filesByStatus} shippingGroupId={shippingGroupId} documents={documents} />
            </Suspense>
          )}

          {!rolSellerAndSupCourier && FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
                shippingGroupId={shippingGroupId}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(PickupScreen)
