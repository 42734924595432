import styled from "styled-components"

const FileUploadStyled = styled.div`
  .subtitle {
    color: #374151;
    font-weight: 500;
  }

  .box-file {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0.25rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: #dddddd dashed 2px;

    & > button {
      color: #2563eb;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-decoration: underline;
    }

    &:hover {
      cursor: pointer;
      border-color: #3b82f6;

      & > button {
        color: #1d4ed8;
      }
    }
  }

  .icon {
    width: 2rem;
    height: 2rem;
    color: #9ca3af;
    margin-bottom: 0.5rem;
  }

  .text-error {
    color: #ef4444;
    margin-top: 0.3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .list-file {
    display: flex;
    padding: 0.5rem;
    margin-top: 0.5rem;
    align-items: center;
    border-radius: 0.25rem;
    background-color: #ffffff;
    justify-content: space-between;

    p {
      flex: 1 1 0%;
      color: #16a34a;
      font-size: 0.875rem;
      line-height: 1.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ico-delete {
      color: #ef4444;
      cursor: pointer;
      margin-left: 0.5rem;

      .ico {
        width: 1rem;
        height: 1rem;
      }

      &:hover {
        color: #b91c1c;
      }
    }
  }
    
  .block {
    display: block;
  }
  .hidden {
    display: none;
  }

  .required {
    color: #FD3A4A;
  }
`

export default FileUploadStyled
